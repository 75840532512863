import React, { ReactElement } from "react";
import {
    Button as MantineButton,
    Text,
    MantineColor,
    ButtonVariant,
} from "@mantine/core";
import classes from "./Button.module.css";

type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    leftSection?: ReactElement;
    rightSection?: ReactElement;
    color?: MantineColor;
    text: string;
    variant?: ButtonVariant;
    rightSectionAbsoluteText?: string;
    disabled?: boolean;
};

function Button({
    onClick,
    leftSection,
    rightSection,
    color,
    text,
    variant = "filled",
    rightSectionAbsoluteText,
    disabled = false,
}: ButtonProps): ReactElement {
    let btnStyleClass = classes.button;

    // disabled button style
    if (disabled) {
        if (variant === "outline") {
            btnStyleClass = `${classes.button} ${classes["secondary-button-disabled"]}`;
        } else {
            btnStyleClass = `${classes.button} ${classes["primary-button-disabled"]}`;
        }
    }
    // active button style
    else if (variant === "outline") {
        btnStyleClass = `${classes.button} ${classes["secondary-button"]}`;
    } else {
        btnStyleClass = `${classes.button} ${classes["primary-button"]}`;
    }

    return (
        <MantineButton
            className={btnStyleClass}
            mb={6}
            fw="bold"
            variant={variant}
            fullWidth
            color={color}
            onClick={onClick}
            leftSection={leftSection}
            rightSection={
                !rightSectionAbsoluteText ? (
                    rightSection
                ) : (
                    <Text pos="absolute" size="sm">
                        {rightSectionAbsoluteText}
                    </Text>
                )
            }
            disabled={disabled}
        >
            <Text fw="bold" size="md">
                {text}
            </Text>
        </MantineButton>
    );
}

export default Button;
