// This file is generated - DO NOT EDIT!
// Change ./src/iconsGenerator/generate.js and run "pnpm run icons:generate"

import React from "react";
import { useComputedColorScheme } from "@mantine/core";
import { ReactComponent as SvgBvgAccessibilityUtilitiesLine } from "../assets/icons/bvg-accessibility-utilities-line.svg";
import { ReactComponent as SvgBvgArrowRightUpDiagonaleLine } from "../assets/icons/bvg-arrow-right-up-diagonale-line.svg";
import { ReactComponent as SvgBvgClockLine } from "../assets/icons/bvg-clock-line.svg";
import { ReactComponent as SvgBvgCommentTextLine } from "../assets/icons/bvg-comment-text-line.svg";
import { ReactComponent as SvgBvgCopyLine } from "../assets/icons/bvg-copy-line.svg";
import { ReactComponent as SvgBvgGlobeLine } from "../assets/icons/bvg-globe-line.svg";
import { ReactComponent as SvgBvgInformationCircleLine } from "../assets/icons/bvg-information-circle-line.svg";
import { ReactComponent as SvgBvgInformationCircleSolid } from "../assets/icons/bvg-information-circle-solid.svg";
import { ReactComponent as SvgBvgInformationLine } from "../assets/icons/bvg-information-line.svg";
import { ReactComponent as SvgBvgKeyLine } from "../assets/icons/bvg-key-line.svg";
import { ReactComponent as SvgBvgListLine } from "../assets/icons/bvg-list-line.svg";
import { ReactComponent as SvgBvgLockLine } from "../assets/icons/bvg-lock-line.svg";
import { ReactComponent as SvgBvgMailLine } from "../assets/icons/bvg-mail-line.svg";
import { ReactComponent as SvgBvgMapLine } from "../assets/icons/bvg-map-line.svg";
import { ReactComponent as SvgBvgMapPinLine } from "../assets/icons/bvg-map-pin-line.svg";
import { ReactComponent as SvgBvgMenuLine } from "../assets/icons/bvg-menu-line.svg";
import { ReactComponent as SvgBvgMoneyCashLine } from "../assets/icons/bvg-money-cash-line.svg";
import { ReactComponent as SvgBvgMoonLine } from "../assets/icons/bvg-moon-line.svg";
import { ReactComponent as SvgBvgNavigationSolid } from "../assets/icons/bvg-navigation-solid.svg";
import { ReactComponent as SvgBvgPersonWheelchairLine } from "../assets/icons/bvg-person-wheelchair-line.svg";
import { ReactComponent as SvgBvgPhoneLine } from "../assets/icons/bvg-phone-line.svg";
import { ReactComponent as SvgBvgTargetEmptyLine } from "../assets/icons/bvg-target-empty-line.svg";
import { ReactComponent as SvgBvgTargetLine } from "../assets/icons/bvg-target-line.svg";
import { ReactComponent as SvgLocationMarkerLine } from "../assets/icons/location-marker-line.svg";

export enum IconComponentFileNames {
    BvgAccessibilityUtilities = "bvg-accessibility-utilities",
    BvgArrowRightUpDiagonale = "bvg-arrow-right-up-diagonale",
    BvgClock = "bvg-clock",
    BvgCommentText = "bvg-comment-text",
    BvgCopy = "bvg-copy",
    BvgGlobe = "bvg-globe",
    BvgInformationCircle = "bvg-information-circle",
    BvgInformation = "bvg-information",
    BvgKey = "bvg-key",
    BvgList = "bvg-list",
    BvgLock = "bvg-lock",
    BvgMail = "bvg-mail",
    BvgMap = "bvg-map",
    BvgMapPin = "bvg-map-pin",
    BvgMenu = "bvg-menu",
    BvgMoneyCash = "bvg-money-cash",
    BvgMoon = "bvg-moon",
    BvgNavigation = "bvg-navigation",
    BvgPersonWheelchair = "bvg-person-wheelchair",
    BvgPhone = "bvg-phone",
    BvgTargetEmpty = "bvg-target-empty",
    BvgTarget = "bvg-target",
    LocationMarker = "location-marker",
}
type IconProps = {
    color?: string;
    size?: number;
    ariaLabel?: string;
};

export type KantineAppIcon = (props: IconProps) => JSX.Element;

export function IconBvgAccessibilityUtilitiesLine({
    color,
    size,
    ariaLabel,
}: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgAccessibilityUtilitiesLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgAccessibilityUtilitiesLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgArrowRightUpDiagonaleLine({
    color,
    size,
    ariaLabel,
}: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgArrowRightUpDiagonaleLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgArrowRightUpDiagonaleLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgClockLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgClockLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgClockLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgCommentTextLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgCommentTextLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgCommentTextLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgCopyLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgCopyLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgCopyLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgGlobeLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgGlobeLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgGlobeLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgInformationCircleLine({
    color,
    size,
    ariaLabel,
}: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgInformationCircleLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgInformationCircleLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgInformationCircleSolid({
    color,
    size,
    ariaLabel,
}: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgInformationCircleSolid
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgInformationCircleSolid
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgInformationLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgInformationLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgInformationLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgKeyLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgKeyLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgKeyLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgListLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgListLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgListLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgLockLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgLockLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgLockLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMailLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMailLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMailLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMapLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMapLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMapLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMapPinLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMapPinLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMapPinLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMenuLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMenuLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMenuLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMoneyCashLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMoneyCashLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMoneyCashLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgMoonLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgMoonLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgMoonLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgNavigationSolid({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgNavigationSolid
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgNavigationSolid
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgPersonWheelchairLine({
    color,
    size,
    ariaLabel,
}: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgPersonWheelchairLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgPersonWheelchairLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgPhoneLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgPhoneLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgPhoneLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgTargetEmptyLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgTargetEmptyLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgTargetEmptyLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconBvgTargetLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgBvgTargetLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgBvgTargetLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}

export function IconLocationMarkerLine({ color, size, ariaLabel }: IconProps) {
    const colorScheme = useComputedColorScheme("light");
    const fillColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-0)"
            : "var(--mantine-color-dark-9)";

    if (color) {
        return (
            <SvgLocationMarkerLine
                aria-label={ariaLabel}
                style={{
                    fill: color || "unset",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            />
        );
    }
    return (
        <SvgLocationMarkerLine
            aria-label={ariaLabel}
            style={{
                fill: fillColor,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
}
