import React, { ReactElement } from "react";
import { Title, Divider, SimpleGrid } from "@mantine/core";

type BottomSheetHeaderProps = {
    title?: string;
    children?: ReactElement;
    dividerStyle?: React.CSSProperties;
};

function BottomSheetHeader({
    title,
    children,
    dividerStyle,
}: BottomSheetHeaderProps): ReactElement {
    return (
        <SimpleGrid spacing={8} w="100%">
            {title && <Title order={3}>{title}</Title>}
            {children}
            <Divider style={dividerStyle} size="sm" />
        </SimpleGrid>
    );
}

export default BottomSheetHeader;
