import React, { createContext, ReactElement, useMemo } from "react";
import { useGeolocated } from "react-geolocated";

const DIGITS_FOR_COORDINATES = 4;

// shorten lat and long to 4 digits after comma to prevent map jumping
// and too many direction requests from frequent updates
function shortenCoords(
    rawCoords: GeolocationCoordinates
): GeolocationCoordinates {
    return {
        // assigned explicitly because spreading of rawCoords only gives empty object
        accuracy: rawCoords.accuracy,
        altitude: rawCoords.altitude,
        altitudeAccuracy: rawCoords.altitudeAccuracy,
        heading: rawCoords.heading,
        latitude: parseFloat(
            rawCoords.latitude.toFixed(DIGITS_FOR_COORDINATES)
        ),
        longitude: parseFloat(
            rawCoords.longitude.toFixed(DIGITS_FOR_COORDINATES)
        ),
        speed: rawCoords.speed,
    };
}

type LocationContextType = {
    coords: GeolocationCoordinates | undefined;
    hasCoords: boolean;
};

export const LocationContext = createContext<LocationContextType>({
    coords: undefined,
    hasCoords: false,
});

function LocationProvider({ children }: { children: ReactElement }) {
    const config = {
        watchPosition: true,
    };

    const { coords: rawCoords } = useGeolocated(config);

    let coords: GeolocationCoordinates | undefined;

    if (rawCoords) {
        coords = shortenCoords(rawCoords);
    }

    const hasCoords = coords !== undefined;

    const contextValue = useMemo(
        () => ({
            coords,
            hasCoords,
        }),
        [coords, hasCoords]
    );

    return (
        <LocationContext.Provider value={contextValue}>
            {children}
        </LocationContext.Provider>
    );
}

export default LocationProvider;
