import React, { ReactElement } from "react";
import { ActionIcon, Center } from "@mantine/core";
import classes from "./MapActionButton.module.css";

type MapActionButtonProps = {
    icon: ReactElement;
    isRound: boolean;
    onClick: () => void;
};

function MapActionButton({
    icon,
    isRound,
    onClick,
}: MapActionButtonProps): ReactElement {
    return (
        <ActionIcon
            size={54}
            variant="default"
            radius={isRound ? "xl" : "md"}
            className={classes.root}
            onClick={onClick}
        >
            <Center className={classes["icon-container"]}>{icon}</Center>
        </ActionIcon>
    );
}

export default MapActionButton;
