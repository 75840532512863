import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import MapActionButton from "../../reusableComponents/MapActionButton/MapActionButton";
import { IconBvgMenuLine as MenuIcon } from "../../GeneratedIcons/Icons";

type MenuButtonProps = {
    onClick: () => void;
};

function MenuButton({ onClick }: MenuButtonProps): ReactElement {
    const { t } = useTranslation();

    return (
        <MapActionButton
            onClick={onClick}
            isRound={false}
            icon={<MenuIcon ariaLabel={t("nav.ariaLabels.openMenu")} />}
        />
    );
}

export default MenuButton;
