import React, { ReactElement } from "react";
import { Group, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import BottomSheetHeader from "../../reusableComponents/BottomSheet/BottomSheetHeader";
import {
    Canteen,
    IDENTIFIER_BSR,
    IDENTIFIER_BWB,
} from "../../services/canteensService";
import { ReactComponent as LogoBVG } from "../../assets/logos/bvg.svg";
import { ReactComponent as LogoBSR } from "../../assets/logos/bsr.svg";
import { ReactComponent as LogoBWB } from "../../assets/logos/bwb.svg";
import classes from "./CanteenDetailsHeader.module.css";
import { IconBvgPersonWheelchairLine as AccessibleIcon } from "../../GeneratedIcons/Icons";

type CanteenDetailsHeaderProps = {
    canteen: Canteen;
};

function CanteenDetailsHeader({
    canteen,
}: CanteenDetailsHeaderProps): ReactElement {
    const { t } = useTranslation();

    let logo = (
        <LogoBVG
            aria-label={t("ariaLabels.logos.bvg")}
            className={classes.logo}
        />
    );
    if (canteen.owner === IDENTIFIER_BSR) {
        logo = (
            <LogoBSR
                aria-label={t("ariaLabels.logos.bsr")}
                className={classes.logo}
            />
        );
    } else if (canteen.owner === IDENTIFIER_BWB) {
        logo = (
            <LogoBWB
                aria-label={t("ariaLabels.logos.bwb")}
                className={classes.logo}
            />
        );
    }

    let company = t("canteenDetailsHeader.company_bvg");
    if (canteen.owner === IDENTIFIER_BSR) {
        company = t("canteenDetailsHeader.company_bsr");
    } else if (canteen.owner === IDENTIFIER_BWB) {
        company = t("canteenDetailsHeader.company_bwb");
    }

    return (
        <BottomSheetHeader>
            <Stack className={classes["header-container"]}>
                <Group gap={0}>
                    {logo}
                    {canteen.owner === IDENTIFIER_BWB ? (
                        <Stack
                            gap={0}
                            className={classes["header-desc-container-bwb"]}
                        >
                            <Text fw={700} className={classes["header-desc"]}>
                                {company}
                            </Text>
                            <Text fw={700} className={classes["header-desc"]}>
                                {t("canteenDetailsHeader.canteen")}
                            </Text>
                        </Stack>
                    ) : (
                        <Text fw={700} className={classes["header-desc"]}>
                            {company} {t("canteenDetailsHeader.canteen")}
                        </Text>
                    )}
                </Group>
                <Group justify="space-between" wrap="nowrap">
                    <Title className={classes.title}>{canteen.name}</Title>
                    {canteen.accessible && (
                        <Group>
                            <AccessibleIcon
                                ariaLabel={t(
                                    "ariaLabels.canteenDetails.accessible"
                                )}
                                size={28}
                            />
                        </Group>
                    )}
                </Group>
            </Stack>
        </BottomSheetHeader>
    );
}

export default CanteenDetailsHeader;
