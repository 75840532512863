import React, { ReactElement } from "react";
import { AttributionControl, MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import VectorTileLayer from "react-leaflet-vector-tile-layer";
import { LatLngBoundsExpression } from "leaflet";

const BVG_TILE_URL = "https://{s}-tiles.bvg.de/data/positron/{z}/{x}/{y}.pbf";
const CENTER_POSITION = { lat: 52.517103, lng: 13.388072 };
const MAX_BOUNDS: LatLngBoundsExpression = [
    [52.275225292581155, 12.970765686035154],
    [52.755176920882604, 13.830910170078278],
];

const MIN_ZOOM = 10;
const MAX_ZOOM = 20;
const ZOOM_SNAP = 0.1;

type BerlinMapProps = {
    zoom: number;
    styleUrl: string;
    attribution: string;
    attributionPrefix?: string;
    children?: ReactElement;
};

function BerlinMap({
    zoom,
    styleUrl,
    attribution,
    attributionPrefix,
    children,
}: BerlinMapProps): ReactElement {
    return (
        <MapContainer
            dragging
            center={CENTER_POSITION}
            zoom={zoom}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            zoomControl={false}
            zoomSnap={ZOOM_SNAP}
            bounceAtZoomLimits={false}
            style={{
                width: "100%",
                height: "100%",
                zIndex: 1,
            }}
            maxBounds={MAX_BOUNDS}
            attributionControl={attributionPrefix === undefined}
        >
            {attributionPrefix && (
                <AttributionControl prefix={attributionPrefix} />
            )}

            <TileLayer
                attribution={attribution}
                url={BVG_TILE_URL}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
            />

            <VectorTileLayer styleUrl={styleUrl} />

            {children}
        </MapContainer>
    );
}

export default BerlinMap;
