import { MantineTheme, MantineColor } from "@mantine/core";
import L from "leaflet";
import { isAfter, startOfDay, startOfToday } from "date-fns";
import bvgCanteens from "../assets/canteenData/bvgCanteens.json";
import bsrCanteens from "../assets/canteenData/bsrCanteens.json";
import bwbCanteens from "../assets/canteenData/bwbCanteens.json";

export const IDENTIFIER_BSR = "BSR";
export const IDENTIFIER_BWB = "BWB";
export const IDENTIFIER_BVG = "BVG";

export type OpeningHours = {
    days: string;
    hours: string[];
};

export type Canteen = {
    id: string;
    name: string;
    coordinates: [number, number]; // [lat, lng]
    street: string;
    postalCode: number;
    phoneNumber: string;
    openingHours: OpeningHours[];
    url?: string;
    owner: string;
    accessible: boolean;
    access: { summary: string; details?: string };
    payment: {
        paymentMethods: string[];
        details: string;
    };
    closingDate?: string; // format: ISO 8601 (date)
    beelineDistance?: number; // beeline from current position in m
};

export const calculateBeeline = (
    position1: L.LatLngExpression,
    position2: L.LatLngExpression
) => {
    return Math.trunc(L.latLng(position1).distanceTo(position2));
};

export const isInService = (canteen: Canteen) => {
    if (canteen.closingDate === undefined) {
        return true;
    }

    return isAfter(startOfDay(new Date(canteen.closingDate)), startOfToday());
};

export const getCanteens = (
    currentPosition?: GeolocationCoordinates
): Canteen[] => {
    let canteens = [
        ...(bsrCanteens as Canteen[]),
        ...(bvgCanteens as Canteen[]),
        ...(bwbCanteens as Canteen[]),
    ];

    canteens = canteens.filter(isInService);

    if (currentPosition) {
        canteens = canteens.map((canteen) => {
            const beelineDistance = calculateBeeline(
                [canteen.coordinates[0], canteen.coordinates[1]],
                [currentPosition.latitude, currentPosition.longitude]
            );
            return { ...canteen, beelineDistance };
        });
    }
    return canteens;
};

export const getCompanyColor = (
    theme: MantineTheme,
    canteenOwner: string
): MantineColor => {
    let companyColor = theme.colors.dark[2];
    /* eslint-disable prefer-destructuring */
    if (canteenOwner === IDENTIFIER_BSR) {
        companyColor = theme.colors.bsr[9];
    } else if (canteenOwner === IDENTIFIER_BWB) {
        companyColor = theme.colors.bwb[9];
    } else if (canteenOwner === IDENTIFIER_BVG) {
        companyColor = theme.colors.bvg[9];
    }
    /* eslint-enable prefer-destructuring */
    return companyColor;
};

export const getPrettyDistance = (distance: number | undefined) => {
    if (distance) {
        let distanceString = `${distance} m`;
        if (distance > 1000) {
            const km = Math.round(distance / 100) / 10;
            const kmString = km.toString().replace(".", ",");
            distanceString = `${kmString} km`;
        }
        return distanceString;
    }
    return "";
};

export const getPrettyDuration = (seconds: number) => {
    if (seconds >= 0) {
        const minutes: number = Math.floor((seconds % 3600) / 60);
        const hours = Math.floor(seconds / 3600);

        if (hours > 0) {
            return `${hours} h ${minutes} min`;
        }
        if (minutes < 1) {
            return `<1 min`;
        }
        return `${minutes} min`;
    }
    return "";
};

export const swapCoordinates = (coordinates: number[][]) => {
    return coordinates.map(([a, b]): L.LatLngTuple => [b, a]);
};
