import React, { ReactElement, useState, useContext, useEffect } from "react";
import { BrowserView, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ZoomControl } from "react-leaflet";
import { useDisclosure } from "@mantine/hooks";
import { Container, useComputedColorScheme } from "@mantine/core";
import BerlinMap from "../../reusableComponents/BerlinMap/BerlinMap";
import { getCanteens } from "../../services/canteensService";
import directions from "../../services/orsService";
import CanteenMarker from "../../projectComponents/CanteenMarker/CanteenMarker";
import CanteenDetails from "../../bottomSheetPages/CanteenDetails/CanteenDetails";
import CurrentPositionMarker from "../../reusableComponents/CurrentPositionMarker/CurrentPositionMarker";
import MapButtons from "../../projectComponents/MapButtons/MapButtons";
import classes from "./CanteenMapPage.module.css";
import MenuButton from "../../projectComponents/MenuButton/MenuButton";
import Navbar from "../../projectComponents/Navbar/Navbar";
import CanteenNavigation from "../../bottomSheetPages/CanteenNavigation/CanteenNavigation";
import { CanteenContext } from "../../providers/CanteenProvider";
import MapRoutePath from "../../projectComponents/MapRoutePath/MapRoutePath";
import { LocationContext } from "../../providers/LocationProvider";
import CenteringController from "../../projectComponents/CenteringController/CenteringController";

function CanteenMapPage(): ReactElement {
    const [canteenDirections, setCanteenDirections] =
        useState<DirectionsResult | null>();
    const [isNavbarOpen, { open: openNavbar, close: closeNavbar }] =
        useDisclosure(false);
    const colorMode = useComputedColorScheme("light");

    const [isNavigationActive, setIsNavigationActive] = useState(false);
    const [shouldCenter, setShouldCenter] = useState(false);

    const { t } = useTranslation();

    const { PUBLIC_URL } = process.env;

    const zoom = isMobile ? 11 : 11.9;

    const { coords } = useContext(LocationContext);

    const canteens = getCanteens(coords);

    const { selectedCanteen, setSelectedCanteen, showRoute, setShowRoute } =
        useContext(CanteenContext);

    async function updateDirections() {
        if (showRoute && coords && selectedCanteen?.coordinates) {
            setCanteenDirections(
                await directions.calculate({
                    coordinates: [
                        [coords.longitude, coords.latitude],
                        [...selectedCanteen.coordinates].reverse(),
                    ],
                    profile: "foot-walking",
                    format: "geojson",
                })
            );
        }
    }

    const mapStyleUrl =
        colorMode === "dark"
            ? `${PUBLIC_URL}/mapStyles/dark-style.json`
            : `${PUBLIC_URL}/mapStyles/bright-style.json`;

    useEffect(() => {
        updateDirections();
    }, [selectedCanteen?.id, showRoute, coords?.latitude, coords?.longitude]);

    return (
        <div style={{ width: "100vw", height: "100vh" }}>
            <BerlinMap
                zoom={zoom}
                key={`color-${colorMode}-map`}
                styleUrl={mapStyleUrl}
                attribution={t("map.attribution")}
                attributionPrefix={t("map.attributionPrefix")}
            >
                <>
                    <CenteringController
                        isNavigationActive={isNavigationActive}
                        setShouldCenter={setShouldCenter}
                        shouldCenter={shouldCenter}
                    />
                    <Navbar isOpen={isNavbarOpen} onClose={closeNavbar} />

                    <BrowserView>
                        <ZoomControl
                            position="topright"
                            zoomInTitle={t("map.ariaLabels.zoomIn")}
                            zoomOutTitle={t("map.ariaLabels.zoomOut")}
                        />
                    </BrowserView>

                    {!isNavigationActive &&
                        canteens.map((canteen) => (
                            <CanteenMarker
                                key={canteen.id}
                                canteen={canteen}
                                onClick={() => setSelectedCanteen(canteen)}
                            />
                        ))}

                    {isNavigationActive && selectedCanteen && (
                        <CanteenMarker
                            key={selectedCanteen.id}
                            canteen={selectedCanteen}
                            onClick={() => setSelectedCanteen(selectedCanteen)}
                        />
                    )}

                    {showRoute && selectedCanteen && canteenDirections ? (
                        <CanteenNavigation
                            canteen={selectedCanteen}
                            onClose={() => {
                                setShowRoute(false);
                            }}
                            canteenDirections={canteenDirections}
                            isNavigationActive={isNavigationActive}
                            setIsNavigationActive={setIsNavigationActive}
                            setShouldCenter={setShouldCenter}
                        />
                    ) : (
                        <CanteenDetails
                            canteen={selectedCanteen}
                            onClose={() => setSelectedCanteen(null)}
                            onShowRoute={() => {
                                setShowRoute(true);
                                setShouldCenter(false);
                            }}
                        />
                    )}

                    <CurrentPositionMarker coords={coords} />

                    <Container
                        px={0}
                        className={classes["action-buttons-root"]}
                    >
                        <MapButtons
                            coords={coords}
                            shouldCenter={shouldCenter}
                            setShouldCenter={setShouldCenter}
                        />
                    </Container>

                    {!showRoute && (
                        <Container
                            px={0}
                            className={classes["action-button-menu"]}
                        >
                            <MenuButton onClick={openNavbar} />
                        </Container>
                    )}
                    {showRoute && canteenDirections && (
                        <MapRoutePath
                            dashed
                            pathCoordinates={
                                canteenDirections.features[0].geometry
                                    .coordinates
                            }
                        />
                    )}
                </>
            </BerlinMap>
        </div>
    );
}

export default CanteenMapPage;
