import React, { ReactElement } from "react";
import {
    Collapse,
    Group,
    Stack,
    Text,
    ThemeIcon,
    useComputedColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
    IconBvgInformationCircleSolid as InformationCircleActive,
    IconBvgInformationCircleLine as InformationCircle,
} from "../../GeneratedIcons/Icons";

import classes from "./CollapsibleInfoItem.module.css";

const COLLAPSIBLE_DESCRIPTION_GAP = 8;
const ICON_SIZE = 22;

type CollapsibleInfoItemProps = {
    icon: ReactElement;
    title: string;
    description?: string;
    openAriaLabel?: string;
    closeAriaLabel?: string;
};

function CollapsibleInfoItem({
    icon,
    title,
    description,
    openAriaLabel,
    closeAriaLabel,
}: CollapsibleInfoItemProps): ReactElement {
    const [opened, { toggle }] = useDisclosure(false);
    const colorScheme = useComputedColorScheme("light");
    const informationCircleColor =
        colorScheme === "dark" ? "var(--mantine-color-white-4)" : "";

    return (
        <Stack gap={COLLAPSIBLE_DESCRIPTION_GAP}>
            <Group
                justify="space-between"
                wrap="nowrap"
                onClick={description ? toggle : undefined}
                onKeyDown={
                    description
                        ? (event) => {
                              const { code } = event;
                              if (code === "Enter" || code === "Space") {
                                  toggle();
                              }
                          }
                        : undefined
                }
                role={description ? "button" : undefined}
                tabIndex={description ? 0 : -1}
                aria-label={opened ? closeAriaLabel : openAriaLabel}
            >
                <Group gap="var(--canteen-info-group-gap)" wrap="nowrap">
                    <Group>{icon}</Group>
                    <Text className={classes.title} size="sm">
                        {title}
                    </Text>
                </Group>
                {description && (
                    <ThemeIcon variant="transparent">
                        {opened ? (
                            <InformationCircleActive
                                ariaLabel={closeAriaLabel}
                                size={ICON_SIZE}
                                color={informationCircleColor}
                            />
                        ) : (
                            <InformationCircle
                                ariaLabel={openAriaLabel}
                                size={ICON_SIZE}
                                color={informationCircleColor}
                            />
                        )}
                    </ThemeIcon>
                )}
            </Group>
            {description && (
                <Collapse in={opened}>
                    <Text className={classes.description} size="sm">
                        {description}
                    </Text>
                </Collapse>
            )}
        </Stack>
    );
}

export default CollapsibleInfoItem;
