import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useFavicon, useTimeout } from "@mantine/hooks";
import CanteenMapPage from "./pages/CanteenMapPage/CanteenMapPage";
import ListViewPage from "./pages/ListViewPage/ListViewPage";
import SplashScreenPage from "./pages/SplashScreenPage/SplashScreenPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import {
    ABOUT,
    ACCESSIBILITY,
    BASE_ROUTE,
    LIST_VIEW,
    PRIVACY_POLICY,
} from "./navigation/routes";
import AccessibilityPage from "./pages/AccessibilityPage/AccessibilityPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";

function App() {
    useFavicon("./favicon_kantinen_app.svg");

    const [showSplashscreen, setShowSplashscreen] = useState(true);

    useTimeout(() => setShowSplashscreen(false), 800, { autoInvoke: true });

    return showSplashscreen ? (
        <SplashScreenPage />
    ) : (
        <BrowserRouter>
            <Routes>
                <Route path={BASE_ROUTE} element={<CanteenMapPage />} />
                <Route path={LIST_VIEW} element={<ListViewPage />} />
                <Route path={ABOUT} element={<AboutPage />} />
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
                <Route path={ACCESSIBILITY} element={<AccessibilityPage />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
