import {
    CloseButton,
    Group,
    lighten,
    SimpleGrid,
    Stack,
    Text,
    useMantineTheme,
    useComputedColorScheme,
} from "@mantine/core";
import React, { ReactElement, SetStateAction, useState } from "react";

import { useTranslation } from "react-i18next";
import BottomSheet from "../../reusableComponents/BottomSheet/BottomSheet";
import BottomSheetFooter from "../../reusableComponents/BottomSheet/BottomSheetFooter";
import Button from "../../reusableComponents/Button/Button";
import {
    Canteen,
    getCompanyColor,
    getPrettyDistance,
    getPrettyDuration,
    IDENTIFIER_BSR,
    IDENTIFIER_BWB,
} from "../../services/canteensService";

type CanteenNavigationProps = {
    canteen: Canteen;
    onClose: (canteen: Canteen) => void;
    canteenDirections: DirectionsResult;
    isNavigationActive: boolean;
    setIsNavigationActive: React.Dispatch<SetStateAction<boolean>>;
    setShouldCenter: React.Dispatch<SetStateAction<boolean>>;
};

function CanteenNavigation({
    canteen,
    onClose,
    canteenDirections,
    isNavigationActive,
    setIsNavigationActive,
    setShouldCenter,
}: CanteenNavigationProps): ReactElement | null {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const [isClosed, setIsClosed] = useState(false);
    const colorScheme = useComputedColorScheme("light");

    const companyColor = getCompanyColor(theme, canteen.owner);
    const companyColorButton = lighten(companyColor, 0.4);

    let company = t("canteenDetailsHeader.company_bvg");
    if (canteen.owner === IDENTIFIER_BSR) {
        company = t("canteenDetailsHeader.company_bsr");
    } else if (canteen.owner === IDENTIFIER_BWB) {
        company = t("canteenDetailsHeader.company_bwb");
    }

    return (
        <BottomSheet
            isOpen={!!canteen && !isClosed}
            onClose={() => onClose(canteen)}
            closeOnDrag={false}
            disableDrag
            closeOnBackdropClick={false}
            showBackdrop={false}
        >
            <>
                <Group gap={4} wrap="nowrap" px={12} py={20}>
                    <Group gap={20} w="100%" wrap="nowrap">
                        <Stack gap={0} align="center">
                            <Text
                                fw="bold"
                                size="lg"
                                lh="100%"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {getPrettyDistance(
                                    canteenDirections.features[0].properties
                                        .summary.distance
                                )}
                            </Text>
                            <Text size="md" style={{ whiteSpace: "nowrap" }}>
                                {getPrettyDuration(
                                    canteenDirections.features[0].properties
                                        .summary.duration
                                )}
                            </Text>
                        </Stack>

                        <Stack gap={0}>
                            <Text c="var(--mantine-color-black-3)" size="xs">
                                {company}
                            </Text>
                            <Text fw="bold" size="lg" lh="120%">
                                {canteen.name}
                            </Text>
                        </Stack>
                    </Group>
                    {isNavigationActive && (
                        <Group justify="flex-end">
                            <CloseButton
                                aria-label={t(
                                    "canteenNavigation.ariaLabels.stopNavigation"
                                )}
                                size="xl"
                                onClick={() => {
                                    setIsNavigationActive(false);
                                    setShouldCenter(false);
                                }}
                                c={
                                    colorScheme === "dark"
                                        ? "var(--mantine-color-dark-0)"
                                        : "var(--mantine-color-dark-9)"
                                }
                            />
                        </Group>
                    )}
                </Group>

                {!isNavigationActive && (
                    <BottomSheetFooter>
                        {}
                        <SimpleGrid spacing={8} cols={1}>
                            <Button
                                color={companyColorButton}
                                onClick={() => {
                                    setIsNavigationActive(true);
                                    setShouldCenter(true);
                                }}
                                text={t("canteenNavigation.navigate")}
                            />

                            <Button
                                color={companyColorButton}
                                onClick={() => {
                                    setIsClosed(true);
                                    onClose(canteen);
                                }}
                                text={t("canteenNavigation.back")}
                                variant="outline"
                            />
                        </SimpleGrid>
                    </BottomSheetFooter>
                )}
            </>
        </BottomSheet>
    );
}

export default CanteenNavigation;
