import React, { ReactNode, SetStateAction, useContext, useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import { CanteenContext } from "../../providers/CanteenProvider";
import { LocationContext } from "../../providers/LocationProvider";

const ZOOM_LEVEL_DEFAULT = 16;
const ZOOM_LEVEL_NAVIGATION = 19;

type CenteringControllerProps = {
    shouldCenter: boolean;
    setShouldCenter: React.Dispatch<SetStateAction<boolean>>;
    isNavigationActive: boolean;
};

function CenteringController({
    shouldCenter,
    setShouldCenter,
    isNavigationActive,
}: CenteringControllerProps): ReactNode {
    const map = useMap();
    const { selectedCanteen, showRoute } = useContext(CanteenContext);
    const { coords } = useContext(LocationContext);

    useMapEvents({
        movestart() {
            setShouldCenter(false);
        },
        click() {
            map.stop(); // stop current animation if any
            setShouldCenter(false);
        },
    });

    // lock map on current position
    useEffect(() => {
        if (!coords || !shouldCenter) {
            return;
        }

        const zoom = isNavigationActive
            ? ZOOM_LEVEL_NAVIGATION
            : ZOOM_LEVEL_DEFAULT;
        map.flyTo([coords.latitude, coords.longitude], zoom, {
            noMoveStart: true,
            duration: 0.7,
        });
    }, [coords?.latitude, coords?.longitude, shouldCenter, isNavigationActive]);

    // initial center on show route
    useEffect(() => {
        if (!coords || !selectedCanteen) {
            return;
        }

        if (showRoute && !isNavigationActive) {
            const boundBorderPoint1: LatLngTuple = [
                coords.latitude,
                coords.longitude,
            ];
            const boundBorderPoint2 = selectedCanteen.coordinates;
            map.flyToBounds([boundBorderPoint1, boundBorderPoint2], {
                paddingBottomRight: [90, 300],
                paddingTopLeft: [50, 50],
                duration: 0.3,
                noMoveStart: true,
            });
        }
    }, [isNavigationActive, showRoute, selectedCanteen?.id]);

    return null;
}

export default CenteringController;
