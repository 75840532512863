import React, { createContext, ReactElement, useMemo } from "react";
import { Canteen } from "../services/canteensService";

type CanteenContextType = {
    selectedCanteen: Canteen | null;
    setSelectedCanteen: (value: Canteen | null) => void;
    showRoute: boolean;
    setShowRoute: (value: boolean) => void;
};

type CanteenProviderType = {
    children: ReactElement;
};

export const CanteenContext = createContext<CanteenContextType>({
    selectedCanteen: null,
    /* eslint-disable @typescript-eslint/no-empty-function */
    setSelectedCanteen: () => {},
    showRoute: false,
    setShowRoute: () => {},
    /* eslint-enable @typescript-eslint/no-empty-function */
});

function CanteenProvider({ children }: CanteenProviderType) {
    const [selectedCanteen, setSelectedCanteen] =
        React.useState<Canteen | null>(null);
    const [showRoute, setShowRoute] = React.useState(false);

    const contextValue = useMemo(
        () => ({
            selectedCanteen,
            setSelectedCanteen,
            showRoute,
            setShowRoute,
        }),
        [selectedCanteen, showRoute]
    );

    return (
        <CanteenContext.Provider value={contextValue}>
            {children}
        </CanteenContext.Provider>
    );
}

export default CanteenProvider;
