import {
    Box,
    Group,
    List,
    ScrollArea,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import mailtoLink from "mailto-link";
import classes from "./AccessibilityPage.module.css";
import PageHeader from "../../projectComponents/PageHeader/PageHeader";
import { IconBvgMailLine as IconMail } from "../../GeneratedIcons/Icons";

function AccessibilityPage(): ReactElement {
    const { t } = useTranslation();
    const { height: viewportHeight } = useViewportSize();
    const [fixedAreaHeight, setFixedAreaHeight] = useState(0);

    const formatText = (text: string) => {
        if (text.includes("@")) {
            return (
                <Group
                    className={classes["link-container"]}
                    gap={4}
                    onClick={() => {
                        window.open(
                            mailtoLink({
                                to: text,
                            })
                        );
                    }}
                >
                    <IconMail ariaLabel={t("ariaLabels.email")} size={20} />
                    <Text
                        component="a"
                        href={mailtoLink({
                            to: text,
                        })}
                        className={classes.link}
                    >
                        {text}
                    </Text>
                </Group>
            );
        }
        if (text.startsWith("https")) {
            return (
                <Text
                    component="a"
                    href={text}
                    className={classes.link}
                    target="_blank"
                >
                    {text}
                </Text>
            );
        }
        return <Text>{text}</Text>;
    };

    return (
        <Stack gap={0} className={classes.container} h="100%">
            <PageHeader
                pageTitle={t("accessibility.title")}
                onGetHeight={(newHeight: number) => {
                    setFixedAreaHeight(newHeight);
                }}
            />
            <ScrollArea.Autosize
                mah={viewportHeight - fixedAreaHeight}
                type="auto"
            >
                <Stack maw={700} pl={32} pr={44} py={32} gap={32} m="0 auto">
                    {(
                        t("accessibility.content", {
                            returnObjects: true,
                        }) as {
                            texts: string[];
                            heading?: string;
                            list?: { title: string; description: string }[];
                        }[]
                    ).map((paragraph, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Stack key={index}>
                            {paragraph.heading && (
                                <Title className={classes.title}>
                                    {paragraph.heading}
                                </Title>
                            )}

                            {paragraph.list && (
                                <List>
                                    {paragraph.list.map(
                                        (listItem, paragraphIndex) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <List.Item key={paragraphIndex}>
                                                <Stack gap="0">
                                                    <Text fw="bold">
                                                        {listItem.title}
                                                    </Text>
                                                    <Text>
                                                        {listItem.description}
                                                    </Text>
                                                </Stack>
                                            </List.Item>
                                        )
                                    )}
                                </List>
                            )}

                            {paragraph.texts.map((text, textIndex) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box key={textIndex}> {formatText(text)} </Box>
                            ))}
                        </Stack>
                    ))}
                    <Text fs="italic">{t("accessibility.date")}</Text>
                </Stack>
            </ScrollArea.Autosize>
        </Stack>
    );
}

export default AccessibilityPage;
