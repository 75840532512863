import React, { ReactElement } from "react";
import { Text, Group, Stack, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import classes from "./SplashScreenPage.module.css";
import { ReactComponent as IconAppLogo } from "../../assets/logo_kantinen_app.svg";
import { ReactComponent as IconBVGLogo } from "../../assets/logos/bvg.svg";
import { ReactComponent as IconBSRLogo } from "../../assets/logos/bsr.svg";
import { ReactComponent as IconBWBLogo } from "../../assets/logos/bwb.svg";

const INFO_STACK_GAP = 8;

function SplashScreenPage(): ReactElement {
    const { t } = useTranslation();
    const { height: viewportHeight } = useViewportSize();

    return (
        <Group
            gap={0}
            justify="center"
            h={viewportHeight}
            className={classes.container}
        >
            <Flex
                mih={50}
                gap="0"
                direction="column"
                className={classes["container-flex"]}
                align="center"
            >
                <Group className={classes["container-logo"]} justify="center">
                    <IconAppLogo
                        aria-label={t("ariaLabels.logos.app")}
                        className={classes.logo}
                    />
                </Group>

                <Group
                    gap={0}
                    className={classes["container-companies"]}
                    py={12}
                >
                    <Stack
                        gap={INFO_STACK_GAP}
                        className={classes["info-stack"]}
                    >
                        <Text size="md" ta="center">
                            {t("splashscreen.developedBy")}
                        </Text>
                        <IconBVGLogo
                            aria-label={t("ariaLabels.logos.bvg")}
                            className={classes["logo-bvg"]}
                        />
                    </Stack>

                    <Stack
                        gap={INFO_STACK_GAP}
                        className={classes["info-stack"]}
                    >
                        <Text size="md" ta="center">
                            {t("splashscreen.coopWith")}
                        </Text>
                        <Group
                            className={classes["logo-container"]}
                            justify="center"
                        >
                            <IconBSRLogo
                                aria-label={t("ariaLabels.logos.bsr")}
                                className={classes["logo-bsr"]}
                            />
                            <IconBWBLogo
                                aria-label={t("ariaLabels.logos.bwb")}
                                className={classes["logo-bwb"]}
                            />
                        </Group>
                    </Stack>
                </Group>
            </Flex>
        </Group>
    );
}

export default SplashScreenPage;
