import React, { ReactElement, SetStateAction, useContext } from "react";
import { Stack } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MapActionButton from "../../reusableComponents/MapActionButton/MapActionButton";
import {
    IconBvgListLine as IconListView,
    IconBvgTargetLine as TargetLine,
    IconBvgTargetEmptyLine as TargetLineEmpty,
} from "../../GeneratedIcons/Icons";
import { CanteenContext } from "../../providers/CanteenProvider";
import { LIST_VIEW } from "../../navigation/routes";

type MapButtonsProps = {
    coords: GeolocationCoordinates | undefined;
    shouldCenter: boolean;
    setShouldCenter: React.Dispatch<SetStateAction<boolean>>;
};

function MapButtons({
    coords,
    shouldCenter,
    setShouldCenter,
}: MapButtonsProps): ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showRoute } = useContext(CanteenContext);

    return (
        <Stack gap={16}>
            {coords && (
                <MapActionButton
                    icon={
                        shouldCenter ? (
                            <TargetLine
                                ariaLabel={t("map.ariaLabels.centerMap")}
                            />
                        ) : (
                            <TargetLineEmpty
                                ariaLabel={t("map.ariaLabels.centerMap")}
                            />
                        )
                    }
                    isRound
                    onClick={() => setShouldCenter(true)}
                />
            )}
            {!showRoute && (
                <MapActionButton
                    icon={
                        <IconListView
                            ariaLabel={t("map.ariaLabels.changeToList")}
                        />
                    }
                    isRound
                    onClick={() => navigate(LIST_VIEW)}
                />
            )}
        </Stack>
    );
}

export default MapButtons;
