import React, { ReactElement } from "react";
import { Polyline } from "react-leaflet";
import { useComputedColorScheme } from "@mantine/core";
import { swapCoordinates } from "../../services/canteensService";

type MapRoutePathProps = {
    color?: string | null;
    thickness?: number;
    dashed?: boolean;
    pathCoordinates: number[][];
};

function MapRoutePath({
    color,
    thickness = 4,
    dashed = false,
    pathCoordinates,
}: MapRoutePathProps): ReactElement {
    const colorScheme = useComputedColorScheme("light");
    const routeColor =
        colorScheme === "dark"
            ? "var(--mantine-color-white-3)"
            : "var(--mantine-color-black-4)";
    return (
        <Polyline
            positions={swapCoordinates(pathCoordinates)}
            pathOptions={{
                color: color || routeColor,
                dashArray: dashed ? [2, 8] : [1],
                weight: thickness,
            }}
        />
    );
}

export default MapRoutePath;
