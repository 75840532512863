import React, { ReactElement, useState } from "react";
import { Container, ScrollArea, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import { ReactComponent as LogoBVG } from "../../assets/logos/bvg.svg";
import { ReactComponent as LogoBSR } from "../../assets/logos/bsr.svg";
import { ReactComponent as LogoBWB } from "../../assets/logos/bwb.svg";
import classes from "./AboutPage.module.css";
import PageHeader from "../../projectComponents/PageHeader/PageHeader";

function AboutPage(): ReactElement {
    const { t } = useTranslation();
    const { height: viewportHeight } = useViewportSize();
    const [fixedAreaHeight, setFixedAreaHeight] = useState(0);

    return (
        <Stack gap={0} className={classes.container} h={viewportHeight}>
            <PageHeader
                pageTitle={t("about.title")}
                onGetHeight={(newHeight: number) => {
                    setFixedAreaHeight(newHeight);
                }}
            />
            <ScrollArea.Autosize
                mah={viewportHeight - fixedAreaHeight}
                type="auto"
            >
                <Container size={512}>
                    <Stack px={16} pt={32} gap={32}>
                        <Text>{t("about.development")}</Text>
                        <Stack gap={8}>
                            <LogoBVG
                                className={classes.logo}
                                aria-label={t("ariaLabels.logos.bvg")}
                            />
                            <Text ta="center" size="sm">
                                {t("about.bvgImgDescription")}
                            </Text>
                        </Stack>
                        <Text>{t("about.cooperation")}</Text>
                        <Stack gap={8}>
                            <LogoBSR
                                className={classes.logo}
                                aria-label={t("ariaLabels.logos.bsr")}
                            />
                            <Text ta="center" size="sm">
                                {t("about.bsrImgDescription")}
                            </Text>
                        </Stack>
                        <Stack gap={8}>
                            <LogoBWB
                                className={classes.logo}
                                aria-label={t("ariaLabels.logos.bwb")}
                            />
                            <Text ta="center" size="sm">
                                {t("about.bwbImgDescription")}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </ScrollArea.Autosize>
        </Stack>
    );
}

export default AboutPage;
