import React, { ReactElement } from "react";
import { Divider, SimpleGrid } from "@mantine/core";

type BottomSheetFooterProps = {
    children: ReactElement;
};

function BottomSheetFooter({ children }: BottomSheetFooterProps): ReactElement {
    return (
        <SimpleGrid spacing={16} mb="xs" w="100%" cols={1}>
            <Divider size="xs" />
            <SimpleGrid cols={1}>{children}</SimpleGrid>
        </SimpleGrid>
    );
}

export default BottomSheetFooter;
