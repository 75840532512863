// ways to access mantine's theme: https://mantine.dev/theming/functions/
// theme object: https://mantine.dev/theming/mantine-provider/#theme-object

import { createTheme } from "@mantine/core";

const canteenAppTheme = createTheme({
    // font
    fontFamily: "Transit",
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "20px",
        xl: "24px",
    },

    // colors
    colors: {
        // generates the different gradients: https://colorkit.co/color-shades-generator/
        bvg: [
            "#fdfbee",
            "#fcf8dd",
            "#faf4cc",
            "#f8f0ba",
            "#f7eca8",
            "#f6e894",
            "#f4e480",
            "#f3e069",
            "#f1db4d",
            "#f0d722", // orig
        ],
        bsr: [
            "#fff1ea",
            "#ffe3d5",
            "#ffd5c1",
            "#ffc6ac",
            "#ffb897",
            "#ffa981",
            "#ff996b",
            "#ff8953",
            "#ff7837",
            "#ff6600", // orig
        ],
        bwb: [
            "#e8f1f9",
            "#d2e3f2",
            "#bcd5eb",
            "#a6c7e5",
            "#90b8de",
            "#79aad6",
            "#639ccf",
            "#4b8ec8",
            "#3080c0",
            "#0072b8", // orig
        ],
        blue: [
            "#e9eefb",
            "#d2def7",
            "#bdcdf3",
            "#a7bdee",
            "#92ace8",
            "#7e9ce3",
            "#6a8bdd",
            "#567ad7",
            "#4368d0",
            "#3156c9",
        ],
        black: [
            "#d5d7e0",
            "#acaebf",
            "#8c8fa3",
            "#666980",
            "#4d4f66",
            "#34354a",
            "#2b2c3d",
            "#1d1e30",
            "#0c0d21",
            "#01010a",
        ],
        white: [
            "#ffffff",
            "#f8f8f8",
            "#eaeaea",
            "#dcdcdc",
            "#cdcdcd",
            "#bfbfbf",
            "#b1b1b1",
            "#a3a3a3",
            "#959595",
            "#878787",
        ],
        // this is used for the default colors of the components in dark mode
        dark: [
            "#ffffff", // default text color
            "#acaebf",
            "#8c8fa3",
            "#666980",
            "#4d4f66",
            "#34354a",
            "#2b2c3d", // default button color
            "#1d1e30",
            "#0c0d21",
            "#01010a",
        ],
    },
    primaryColor: "blue",
});

export default canteenAppTheme;
