import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { MantineProvider } from "@mantine/core";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./config/i18n";
import "./styles/global.css";
import canteenAppTheme from "./theme";
import CanteenProvider from "./providers/CanteenProvider";
import LocationProvider from "./providers/LocationProvider";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <MantineProvider theme={canteenAppTheme} defaultColorScheme="auto">
            <LocationProvider>
                <CanteenProvider>
                    <App />
                </CanteenProvider>
            </LocationProvider>
        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
