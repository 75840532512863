import {
    CloseButton,
    Divider,
    Drawer,
    Group,
    Text,
    Stack,
} from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconLogo } from "../../assets/logo_kantinen_app.svg";

import classes from "./Navbar.module.css";
import NavbarMenu from "./NavbarMenu/NavbarMenu";

type NavbarProps = {
    isOpen: boolean;
    onClose: () => void;
};

function Navbar({ isOpen, onClose }: NavbarProps): ReactElement {
    const { t } = useTranslation();

    return (
        <Drawer.Root opened={isOpen} onClose={onClose}>
            <Drawer.Overlay />
            <Drawer.Content>
                <div className={classes["content-container"]}>
                    <Drawer.Header p={0}>
                        <Drawer.Title w="100%">
                            <Group
                                justify="space-between"
                                className={classes["header-container"]}
                            >
                                <Group>
                                    <IconLogo
                                        aria-label={t("ariaLabels.logos.app")}
                                        className={classes["app-logo"]}
                                    />
                                    <Text
                                        fw={700}
                                        className={classes["header-title"]}
                                    >
                                        {t("appName")}
                                    </Text>
                                </Group>
                                <CloseButton
                                    aria-label={t("ariaLabels.closePage")}
                                    size="xl"
                                    onClick={onClose}
                                    className={classes["close-button"]}
                                />
                            </Group>
                            <Divider />
                        </Drawer.Title>
                    </Drawer.Header>

                    <NavbarMenu onClose={onClose} />

                    <Stack
                        className={classes["version-container"]}
                        justify="flex-end"
                    >
                        <Stack>
                            <Divider />
                            <Text
                                className={classes["version-footer-text"]}
                                size="sm"
                            >{`${t("nav.versionPrefix")} ${
                                process.env.REACT_APP_VERSION
                            }${process.env.REACT_APP_STAGE_NAME_SUFFIX}`}</Text>
                        </Stack>
                    </Stack>
                </div>
            </Drawer.Content>
        </Drawer.Root>
    );
}

export default Navbar;
