import React, { ReactElement, useContext } from "react";
import { Text, Card, Group, Flex, Stack, SimpleGrid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
    Canteen,
    IDENTIFIER_BSR,
    IDENTIFIER_BWB,
    getCanteens,
    getPrettyDistance,
} from "../../services/canteensService";
import { ReactComponent as LogoBVG } from "../../assets/logos/bvg.svg";
import { ReactComponent as LogoBSR } from "../../assets/logos/bsr.svg";
import { ReactComponent as LogoBWB } from "../../assets/logos/bwb.svg";
import { IconBvgPersonWheelchairLine as AccessibleIcon } from "../../GeneratedIcons/Icons";
import classes from "./CanteenList.module.css";
import { LocationContext } from "../../providers/LocationProvider";

type CanteenListProps = {
    sortAlphabetically?: boolean;
    onClickHandler: (canteen: Canteen) => void;
};

function CanteenList({
    sortAlphabetically,
    onClickHandler,
}: CanteenListProps): ReactElement {
    const { t } = useTranslation();
    const { coords } = useContext(LocationContext);
    const canteens = getCanteens(coords);

    let sortedCanteens: Canteen[] = [];

    sortedCanteens = sortAlphabetically
        ? canteens.sort((a, b) => a.name.localeCompare(b.name))
        : canteens.sort((a, b) => a.beelineDistance! - b.beelineDistance!);

    const listEntries = sortedCanteens.map((canteen) => {
        let logo = (
            <LogoBVG
                aria-label={t("ariaLabels.logos.bvg")}
                className={classes.logo}
            />
        );
        if (canteen.owner === IDENTIFIER_BSR) {
            logo = (
                <LogoBSR
                    aria-label={t("ariaLabels.logos.bsr")}
                    className={classes.logo}
                />
            );
        } else if (canteen.owner === IDENTIFIER_BWB) {
            logo = (
                <LogoBWB
                    aria-label={t("ariaLabels.logos.bwb")}
                    className={classes.logo}
                />
            );
        }

        const prettyDistance = getPrettyDistance(canteen.beelineDistance);
        return (
            <Card
                className={classes.card}
                key={canteen.id}
                p={16}
                radius="md"
                maw={700}
                w="100%"
                onClick={() => onClickHandler(canteen)}
                onKeyDown={({ code }) => {
                    if (code === "Enter") {
                        onClickHandler(canteen);
                    }
                }}
                style={{ cursor: "pointer" }}
                role="button"
                tabIndex={0}
            >
                <SimpleGrid cols={1} spacing={0} mih={98}>
                    <Group justify="space-between" wrap="nowrap">
                        <Text size="sm">{prettyDistance}</Text>
                        {logo}
                    </Group>
                    <Flex
                        justify="space-between"
                        align="flex-end"
                        wrap="nowrap"
                    >
                        <Text fw="bold" size="lg" lh="130%">
                            {canteen.name}
                        </Text>
                        {canteen.accessible && (
                            <Group>
                                <AccessibleIcon
                                    ariaLabel={t(
                                        "ariaLabels.canteenDetails.accessible"
                                    )}
                                    size={28}
                                />
                            </Group>
                        )}
                    </Flex>
                </SimpleGrid>
            </Card>
        );
    });

    return (
        <Stack gap={12} align="center">
            {listEntries}
        </Stack>
    );
}

export default CanteenList;
