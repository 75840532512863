import React, { ReactElement, useEffect } from "react";
import {
    Group,
    CloseButton,
    Text,
    useComputedColorScheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useElementSize } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import classes from "./PageHeader.module.css";
import { BASE_ROUTE } from "../../navigation/routes";

type PageHeaderProps = {
    pageTitle: string;
    onGetHeight?: (height: number) => void;
};

function PageHeader({ pageTitle, onGetHeight }: PageHeaderProps): ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const colorScheme = useComputedColorScheme("light");
    const { ref, height } = useElementSize();

    const paddingTop = 16;
    const paddingBottom = 12;

    useEffect(() => {
        if (onGetHeight) {
            onGetHeight(height + paddingBottom + paddingTop + 2); // fixing buggy behavior
        }
    }, [height]);

    return (
        <Group
            gap={0}
            ref={ref}
            wrap="nowrap"
            justify="space-between"
            className={classes.container}
            pt={paddingTop}
            pb={paddingBottom}
            pl={8}
            pr={16}
        >
            <Text size="xl" fw="bold" pl="16px" lh="100%">
                {pageTitle}
            </Text>
            <CloseButton
                aria-label={t("ariaLabels.closePage")}
                size="xl"
                onClick={() => navigate(BASE_ROUTE)}
                c={
                    colorScheme === "dark"
                        ? "var(--mantine-color-dark-0)"
                        : "var(--mantine-color-dark-9)"
                }
            />
        </Group>
    );
}

export default PageHeader;
