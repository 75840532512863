const de = {
    appName: "Kantinen-App",
    ariaLabels: {
        closePage: "Zurück zur Karte",
        email: "E-Mail",
        logos: {
            app: "Logo der Kantinen-App",
            bvg: "Logo der Berliner Verkehrsbetriebe (BVG)",
            bsr: "Logo der Berliner Stadtreinigung (BSR)",
            bwb: "Logo der Berliner Wasserbetriebe",
        },
        canteenDetails: { accessible: "Barrierefreie Kantine" },
    },
    map: {
        ariaLabels: {
            zoomIn: "Karte heranzoomen",
            zoomOut: "Karte herauszoomen",
            centerMap: "Karte auf aktuellen Standort zentrieren",
            changeToList: "Liste aller Kantinen öffnen",
            openCanteenDetails: "Details von {{canteenName}} öffnen",
            currentPosition: "Aktueller Standort",
        },
        attribution: `Daten von <a tabindex="-1" href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> - Veröffentlicht unter <a tabindex="-1" href="https://opendatacommons.org/licenses/odbl/" target="_blank">ODbL</a>`,
        attributionPrefix: `<a tabindex="-1" href="https://leafletjs.com/">Leaflet</a>`,
    },
    canteenDetails: {
        startNavigation: "Navigieren",
        showRoute: "Route anzeigen",
        openingHours: "Öffnungszeiten:",
        goToMenu: "Zum Speiseplan",
        accessible: "Barrierefrei",
        notAccessible: "Nicht barrierefrei",
        closingInfo: "Diese Kantine ist ab dem {{closingDate}} geschlossen.",
        tooltip: {
            address_copied: "Adresse kopiert!",
            menu_disabled:
                "Für diese Kantine liegt zur Zeit kein Speiseplan vor",
        },
        ariaLabels: {
            address: "Adresse",
            phoneNumber: "Telefonnummer",
            accessible: "Information über die Barrierefreiheit",
            access: "Zugangsinformationen",
            payment: "Zahlungsmöglichkeiten",
            copyAddress: "Adresse kopieren",
            openAccessInfo: "Mehr Informationen zum Zugang anzeigen",
            closeAccessInfo: "Zusätzliche Informationen zum Zugang ausblenden",
            openPaymentInfo:
                "Mehr Informationen zu den Zahlungsmöglichkeiten anzeigen",
            closePaymentInfo:
                "Zusätzliche Informationen zu denZahlungsmöglichkeiten ausblenden",
            bottomSheetBackdrop: "Kantinen-Details schließen",
        },
    },
    canteenDetailsHeader: {
        company_bvg: "Berliner Verkehrsbetriebe",
        company_bsr: "Berliner Stadtreinigung",
        company_bwb: "Berliner Wasserbetriebe",
        canteen: "Kantine",
    },
    canteenListView: {
        title: "Kantinen",
        sortSwitch: {
            distance: "Sortierung nach Entfernung",
            disabledTooltip:
                "Sortierung nach Entfernung benötigt die Standort-Freigabe",
        },
    },
    canteenNavigation: {
        navigate: "Navigation starten",
        back: "Zurück",
        ariaLabels: {
            stopNavigation: "Navigation beenden",
        },
    },
    nav: {
        map: "Karte",
        canteenList: "Liste",
        colorScheme: "Hell- / Dunkelmodus",
        colorSchemeSystem: "Systemgesteuert",
        colorSchemeDark: "Dunkel",
        colorSchemeLight: "Hell",
        information: "Informationen",
        about: "Über diese App",
        imprint: "Impressum",
        privacyPolicy: "Datenschutz",
        accessibility: "Barrierefreiheit",
        feedback: {
            title: "Feedback geben",
            subject: "Feedback zur Kantinen-App",
        },
        versionPrefix: "Version",
        ariaLabels: {
            openMenu: "Menü öffnen",
        },
    },
    about: {
        title: "Über diese App",
        development:
            "Diese App ist eine Entwicklung der Berliner Verkehrsbetriebe",
        cooperation:
            "in Kooperation mit der Berliner Stadtreinigung und den Berliner Wasserbetrieben",
        bvgImgDescription: "Berliner Verkehrsbetriebe (BVG)",
        bsrImgDescription: "Berliner Stadtreinigung (BSR)",
        bwbImgDescription: "Berliner Wasserbetriebe (BWB)",
    },
    splashscreen: {
        developedBy: "Entwickelt von",
        coopWith: "In Kooperation mit",
    },
    accessibility: {
        title: "Erklärung zur digitalen Barrierefreiheit",
        date: "Stand: 05. Dezember 2023",
        content: [
            {
                texts: [
                    "Die Berliner Verkehrsbetriebe (BVG) sind bemüht, die Webanwendung https://kantinen.berlin barrierefrei zugänglich zu machen.",
                    "Die Erklärung zur digitalen Barrierefreiheit wird im Gesetz über die barrierefreie Informations- und Kommunikationstechnik Berlin (BIKTG Bln) verlangt. Die technischen Anforderungen zur Barrierefreiheit ergeben sich aus der BITV 2.0.",
                    "Die Überprüfung wurde im Rahmen der technischen Umsetzung durch die BVG durchgeführt.",
                ],
            },
            {
                heading: "Wie barrierefrei ist das Angebot?",
                texts: [
                    "Dieser Webauftritt ist teilweise barrierefrei. Es werden teilweise die Anforderungen der BITV 2.0 erfüllt.",
                ],
            },
            {
                heading: "Welche Bereiche sind nicht barrierefrei?",
                list: [
                    {
                        title: "Barriere: Alternativtexte für Bedienelemente",
                        description:
                            "Einige Bedienelemente haben keinen Alternativtext.",
                    },
                    {
                        title: "Barriere: Alternativtexte für Grafiken und Objekte",
                        description:
                            "Einige Grafiken haben keinen Alternativtext.",
                    },
                    {
                        title: "Barriere: HTML-Strukturelemente für Listen",
                        description:
                            "Elemente innerhalb des Menüs und in der Listenansicht sind nicht als Liste umgesetzt.",
                    },
                    {
                        title: "Barriere: Kontraste von Texten ausreichend",
                        description:
                            "Einige Text-Elemente besitzen keine definierte Hintergrundfarbe.",
                    },
                    {
                        title: "Barriere: Text auf 200% vergrößerbar",
                        description:
                            "Bei starker Vergrößerung der Website wird die Route zur einigen Kantinen nicht komplett angezeigt.",
                    },
                    {
                        title: "Barriere: Ohne Maus nutzbar",
                        description: "Die Website ist nicht ohne Maus nutzbar.",
                    },
                    {
                        title: "Barriere: Keine Tastaturfalle",
                        description:
                            "Die Website ist nicht für eine Tastaturbedienung ausgelegt. Kantinen können nicht ausgewählt und somit keine Informationen zu diesen abgerufen werden.",
                    },
                    {
                        title: "Barriere: Schlüssige Reihenfolge bei der Tastaturbedienung",
                        description:
                            "Die Website ist nicht für eine Tastaturbedienung ausgelegt.",
                    },
                    {
                        title: "Barriere: Sichtbare Beschriftung Teil des zugänglichen Namens",
                        description:
                            "Einige Bedienelemente haben keinen Alternativtext.",
                    },
                    {
                        title: "Barriere: Keine unerwartete Kontextänderung bei Fokus",
                        description:
                            "Die Website ist nicht für eine Tastaturbedienung ausgelegt.",
                    },
                    {
                        title: "Barriere: Name, Rolle, Wert verfügbar",
                        description:
                            "Kantinen auf der Karte und in der Liste sind nicht als klickbare Elemente ausgezeichnet.",
                    },
                ],
                texts: [
                    "Die Beseitigung dieser bekannten Mängel wird derzeit geplant. Sobald diese Planung abgeschlossen ist, wird sie auf dieser Seite veröffentlicht.",
                ],
            },
            {
                heading:
                    "Wen kannst du bei Anmerkungen oder Fragen zur digitalen Barrierefreiheit kontaktieren?",
                texts: [
                    "Dir sind Mängel beim barrierefreien Zugang zu Inhalten von ",
                    "https://kantinen.berlin",
                    " aufgefallen? Dann melde dich gerne bei uns via E-Mail: ",
                    "Support.Betriebsgastronomie@bvg.de",
                    " oder über die Feedback-Funktion.",
                ],
            },
            {
                heading: "Kontakt zur Schlichtungsstelle",
                texts: [
                    "Wenn auch nach deinem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde, kannst du dich an die Schlichtungsstelle nach § 16 BGG per Email wenden: ",
                    "info@schlichtungsstelle-bgg.de",
                ],
            },
            {
                heading:
                    "Kontakt zur Landesbeauftragten für digitale Barrierefreiheit",
                texts: [
                    "Wenn deine Kontaktaufnahme mit der öffentlichen Stelle nicht erfolgreich war, kannst du dich an die Landesbeauftragte für digitale Barrierefreiheit wenden.",
                    "Weitere Informationen zur Landesbeauftragten für digitale Barrierefreiheit: ",
                    "https://www.berlin.de/lb/digitale-barrierefreiheit/",
                ],
            },
        ],
    },
    privacyPolicy: {
        title: "Datenschutzerklärung",
        date: "Stand: 05. Dezember 2023",
        content: [
            {
                heading: "1. Allgemeines",
            },
            {
                text: "Die Berliner Verkehrsbetriebe AöR, Holzmarktstraße 15-17, 10179 Berlin („BVG“ oder „wir“) beachtet die gesetzlichen Datenschutzbestimmungen. Daten der Nutzer*innen werden vertraulich behandelt. Eine Weitergabe an Dritte erfolgt nur, soweit dies die Datenschutzbestimmungen gestatten oder der*die Nutzer*in hierin einwilligt.",
            },
            {
                text: "Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen (Art. 4 Nr. 1 EU-Datenschutzgrundverordnung („DSGVO“)). ",
            },
            {
                text: "Für eine direkte Kontaktaufnahme mit unserem Datenschutzbeauftragten beachten Sie bitte Ziffer 4.2.",
            },
            {
                heading:
                    "2. Einführung zur Datenverarbeitung bei der Nutzung der Applikation",
            },
            {
                text: "Die Datenverarbeitung umfasst diejenigen personenbezogenen Daten, welche benötigt werden, um die Nutzung der Anwendung zu ermöglichen. Eine Erhebung oder Verarbeitung weiterer personenbezogener Daten findet nur statt, soweit Sie der Verarbeitung zustimmen oder eine andere Rechtsgrundlage dies gestattet. Dies umfasst insbesondere die Standortdaten, die eine Routenführung ermöglichen. Eine Verpflichtung zur Bereitstellung Ihrer personenbezogenen Daten besteht nicht.  Falls Sie die jeweils erforderlichen Daten nicht bereitstellen, kann die entsprechende Dienstleistung jedoch nicht erbracht werden.",
            },
            {
                text: "Um die Daten sicher zu übertragen, verwenden wir die aktuelle Version des Verschlüsselungsprotokolls TLS 1.3. Damit werden die Daten vor dem Zugriff und der Manipulation unbefugter Dritter geschützt.",
            },
            {
                heading: "3. Nutzung von Funktionen unserer Applikation",
            },
            {
                text: "Nachfolgend informieren wir Sie über die Datenverarbeitungen, die wir selbst im Zusammenhang mit den verschiedenen von Ihnen genutzten Funktionen unserer Applikation durchführen.",
            },
            {
                subheading: "3.1 Verarbeitete Daten",
            },
            {
                text: "Folgende Kategorien von Daten werden regelmäßig verarbeitet:",
            },
            {
                text: "- Aktueller Standort, falls freigegeben",
            },
            {
                text: "Falls Sie Zugriff auf Ihren aktuellen Standort erlauben, nutzen wir diese, um eine Routenführung zu ermöglichen und die Distanz zu einem ausgewählten anzuzeigen. Der Standort wird nicht gespeichert oder extern weitergegeben. Auch ohne Standortfreigabe können Sie die Applikation nutzen, die o.g. Features (Routenführung, Distanzanzeige) sind aber dann nicht verfügbar.",
            },
            {
                subheading: "3.2 Kontaktaufnahme per E-Mail",
            },
            {
                text: "Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden Ihre Anfrage und die darin enthaltenen Informationen verarbeitet, um den Grund der Kontaktaufnahme zu ermitteln und Ihnen entsprechend zu helfen. Bei der Kontaktaufnahme durch Sie ist es hilfreich, wenn Sie sich auf die Übermittlung von Informationen beschränken, die für Ihre Anliegen zwingend erforderlich sind.",
            },
            {
                text: "Wenn der Grund für die Datenverarbeitung wegfällt, werden alle von Ihnen eingegebenen personenbezogenen Daten gelöscht. Daten, die für die Vertragsbearbeitung benötigt werden oder gesetzlichen Aufbewahrungsfristen (z.B. steuerliche Gründe) unterliegen, bleiben hiervon unberührt.",
            },
            {
                heading: "4. Sonstiges",
            },
            {
                subheading: "4.1 Aktualisierung der Datenschutzhinweise",
            },
            {
                text: "Wir passen den Datenschutzhinweis an veränderte Funktionalitäten oder geänderte Rechtslagen an. ",
            },
            {
                subheading: "4.2 Kontakt",
            },
            {
                text: "Für Fragen, Anregungen oder Kommentare zum Thema Datenschutz können Sie sich gerne an unseren Datenschutzbeauftragten wenden.",
            },
            {
                text: "Kontaktinformationen:",
            },
            {
                contact: {
                    department: [
                        "Datenschutzbeauftragter",
                        "Berliner Verkehrsbetriebe (BVG)",
                        "Anstalt des öffentlichen Rechts",
                    ],
                    address: ["Holzmarktstraße 15-17", "10179 Berlin"],
                    text: "oder per E-Mail:",
                    email: "datenschutz@bvg.de",
                },
            },
            {
                subheading: "4.3 Aufsichtsbehörde",
            },
            {
                text: "Sie können sich in allen Fragen zum Datenschutz auch an die für Berlin zuständige Aufsichtsbehörde wenden:",
            },
            {
                contact: {
                    department: [
                        "Berliner Beauftragte für Datenschutz und Informationsfreiheit",
                    ],
                    address: [
                        "Alt-Moabit 59 - 61",
                        "(Besuchereingang Alt-Moabit 60)",
                        "10555 Berlin",
                    ],
                    telephone: "Telefon: +49 (30) 13889-0",
                    fax: "Fax: +49 (30) 2155050",
                    email: "mailbox@datenschutz-berlin.de",
                },
            },
        ],
    },
};

export default de;
