import React, { ReactElement } from "react";
import {
    Divider,
    NavLink,
    Stack,
    Text,
    Radio,
    useMantineColorScheme,
    MantineColorScheme,
    useComputedColorScheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import mailtoLink from "mailto-link";
import {
    IconBvgMapLine as IconMap,
    IconBvgListLine as IconList,
    IconBvgMoonLine as IconDarkMode,
    IconBvgInformationLine as IconInfo,
    IconBvgArrowRightUpDiagonaleLine as ArrowRightUpDiag,
    IconBvgGlobeLine as IconAbout,
    IconBvgCommentTextLine as IconComment,
    IconBvgAccessibilityUtilitiesLine as IconAccessibility,
    IconBvgLockLine as IconPrivacyPolicy,
} from "../../../GeneratedIcons/Icons";
import classes from "./NavbarMenu.module.css";
import {
    ABOUT,
    ACCESSIBILITY,
    LIST_VIEW,
    PRIVACY_POLICY,
} from "../../../navigation/routes";
import { LEGAL_DISCLOSURE } from "../../../navigation/links";

type NavbarMenuProps = {
    onClose: () => void;
};
const ICON_SIZE = 26;

function NavbarMenu({ onClose }: NavbarMenuProps): ReactElement {
    const { t } = useTranslation();
    const { colorScheme: radioBtnInput, setColorScheme } =
        useMantineColorScheme();
    const colorScheme = useComputedColorScheme("light");

    const navigate = useNavigate();
    const isDarkMode = colorScheme === "dark";

    const radioButtonStyle = {
        labelWrapper: { width: "100%", paddingLeft: "4px" },
        radio: {
            backgroundColor: isDarkMode ? "var(--mantine-color-dark-0)" : "",
        },
        icon: { color: isDarkMode ? "black" : "" },
    };

    return (
        <Stack p={16}>
            <NavLink
                data-autofocus
                classNames={{
                    root: classes["nav-link"],
                }}
                label={
                    <Text className={classes["nav-text"]}>{t("nav.map")}</Text>
                }
                leftSection={
                    <IconMap ariaLabel={t("nav.map")} size={ICON_SIZE} />
                }
                onClick={onClose}
                href="#" // required for focus
            />
            <NavLink
                className={classes["nav-link"]}
                label={
                    <Text className={classes["nav-text"]}>
                        {t("nav.canteenList")}
                    </Text>
                }
                leftSection={
                    <IconList
                        ariaLabel={t("nav.canteenList")}
                        size={ICON_SIZE}
                    />
                }
                onClick={() => {
                    navigate(LIST_VIEW);
                }}
                href="#" // required for focus
            />
            <Divider mx={12} />
            <NavLink
                classNames={{
                    root: classes["nav-link"],
                    chevron: classes["navlink-chevron"],
                }}
                label={
                    <Text className={classes["nav-text"]}>
                        {t("nav.colorScheme")}
                    </Text>
                }
                leftSection={
                    <IconDarkMode
                        ariaLabel={t("nav.colorScheme")}
                        size={ICON_SIZE}
                    />
                }
                href="#" // required for focus
            >
                <Radio.Group
                    value={radioBtnInput}
                    onChange={(selectedValue: MantineColorScheme) =>
                        setColorScheme(selectedValue)
                    }
                    name="darkModeSelection"
                    pl={40}
                >
                    <Stack gap={20} mb={8}>
                        <Radio
                            size="lg"
                            color="black"
                            value="auto"
                            label={t("nav.colorSchemeSystem")}
                            styles={radioButtonStyle}
                        />
                        <Radio
                            size="lg"
                            color="black"
                            value="dark"
                            label={t("nav.colorSchemeDark")}
                            styles={radioButtonStyle}
                        />
                        <Radio
                            size="lg"
                            color="black"
                            value="light"
                            label={t("nav.colorSchemeLight")}
                            styles={radioButtonStyle}
                        />
                    </Stack>
                </Radio.Group>
            </NavLink>
            <Divider mx={12} />
            <NavLink
                classNames={{
                    root: classes["nav-link"],
                    chevron: classes["navlink-chevron"],
                }}
                label={
                    <Text className={classes["nav-text"]}>
                        {t("nav.information")}
                    </Text>
                }
                leftSection={
                    <IconInfo
                        ariaLabel={t("nav.information")}
                        size={ICON_SIZE}
                    />
                }
                href="#" // required for focus
            >
                <Stack gap={8}>
                    <NavLink
                        className={classes["nav-link"]}
                        label={
                            <Text className={classes["nav-text"]}>
                                {t("nav.about")}
                            </Text>
                        }
                        leftSection={
                            <IconAbout
                                ariaLabel={t("nav.about")}
                                size={ICON_SIZE}
                            />
                        }
                        onClick={() => {
                            navigate(ABOUT);
                        }}
                        href="#" // required for focus
                    />
                    <NavLink
                        className={classes["nav-link"]}
                        label={
                            <Text className={classes["nav-text"]}>
                                {t("nav.imprint")}
                            </Text>
                        }
                        leftSection={
                            <ArrowRightUpDiag
                                ariaLabel={t("nav.imprint")}
                                size={ICON_SIZE}
                            />
                        }
                        onClick={() => {
                            window.open(LEGAL_DISCLOSURE, "_blank");
                        }}
                        href="#" // required for focus
                    />
                    <NavLink
                        className={classes["nav-link"]}
                        label={
                            <Text className={classes["nav-text"]}>
                                {t("nav.privacyPolicy")}
                            </Text>
                        }
                        leftSection={
                            <IconPrivacyPolicy
                                ariaLabel={t("nav.privacyPolicy")}
                                size={ICON_SIZE}
                            />
                        }
                        onClick={() => {
                            navigate(PRIVACY_POLICY);
                        }}
                        href="#" // required for focus
                    />
                    <NavLink
                        className={classes["nav-link"]}
                        label={
                            <Text className={classes["nav-text"]}>
                                {t("nav.accessibility")}
                            </Text>
                        }
                        leftSection={
                            <IconAccessibility
                                ariaLabel={t("nav.accessibility")}
                                size={ICON_SIZE}
                            />
                        }
                        onClick={() => {
                            navigate(ACCESSIBILITY);
                        }}
                        href="#" // required for focus
                    />
                </Stack>
            </NavLink>
            <Divider mx={12} />
            <NavLink
                className={classes["nav-link"]}
                label={
                    <Text className={classes["nav-text"]}>
                        {t("nav.feedback.title")}
                    </Text>
                }
                leftSection={
                    <IconComment
                        ariaLabel={t("nav.feedback.title")}
                        size={ICON_SIZE}
                    />
                }
                href={mailtoLink({
                    to: process.env.REACT_APP_FEEDBACK_MAIL,
                    subject: t("nav.feedback.subject"),
                })}
            />
        </Stack>
    );
}

export default NavbarMenu;
