import { ScrollArea, Stack, Title, Text, Group, Box } from "@mantine/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import mailtoLink from "mailto-link";
import classes from "./PrivacyPolicyPage.module.css";
import { IconBvgMailLine as IconMail } from "../../GeneratedIcons/Icons";
import PageHeader from "../../projectComponents/PageHeader/PageHeader";

function PrivacyPolicyPage(): ReactElement {
    const { t } = useTranslation();
    const { height: viewportHeight } = useViewportSize();
    const [fixedAreaHeight, setFixedAreaHeight] = useState(0);

    return (
        <Stack gap={0} className={classes.container} h="100%">
            <PageHeader
                pageTitle={t("privacyPolicy.title")}
                onGetHeight={(newHeight: number) => {
                    setFixedAreaHeight(newHeight);
                }}
            />
            <ScrollArea.Autosize
                mah={viewportHeight - fixedAreaHeight}
                type="auto"
            >
                <Stack maw={700} mx="auto" px={16} py={32} gap={32}>
                    {(
                        t("privacyPolicy.content", {
                            returnObjects: true,
                        }) as {
                            heading?: string;
                            subheading?: string;
                            text?: string;
                            contact?: {
                                department?: [string];
                                address?: [string];
                                text?: string;
                                telephone?: string;
                                fax?: string;
                                email?: string;
                            };
                        }[]
                    ).map((paragraph, paragraphIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={paragraphIndex}>
                            {paragraph.heading && (
                                <Title className={classes.heading}>
                                    {paragraph.heading}
                                </Title>
                            )}
                            {paragraph.subheading && (
                                <Title className={classes.subheading}>
                                    {paragraph.subheading}
                                </Title>
                            )}
                            {paragraph.text && <Text>{paragraph.text}</Text>}
                            {paragraph.contact && (
                                <Stack gap={0}>
                                    <Stack gap={0} mb={12}>
                                        {paragraph.contact.department?.map(
                                            (department, deptIndex) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Text key={deptIndex}>
                                                    {department}
                                                </Text>
                                            )
                                        )}
                                    </Stack>
                                    {paragraph.contact.address?.map(
                                        (address, addressIndex) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <Text key={addressIndex}>
                                                {address}
                                            </Text>
                                        )
                                    )}
                                    <Text mt={12}>
                                        {paragraph.contact.telephone}
                                    </Text>
                                    <Text>{paragraph.contact.fax}</Text>
                                    <Text>{paragraph.contact.text}</Text>

                                    <Group
                                        className={classes["link-container"]}
                                        gap={4}
                                        onClick={() => {
                                            window.open(
                                                mailtoLink({
                                                    to: paragraph.contact
                                                        ?.email,
                                                })
                                            );
                                        }}
                                    >
                                        <IconMail
                                            ariaLabel={t("ariaLabels.email")}
                                            size={20}
                                        />
                                        <Text
                                            component="a"
                                            href={mailtoLink({
                                                to: paragraph.contact.email,
                                            })}
                                            className={classes.link}
                                        >
                                            {paragraph.contact.email}
                                        </Text>
                                    </Group>
                                </Stack>
                            )}
                        </Box>
                    ))}
                    <Text fs="italic">{t("privacyPolicy.date")}</Text>
                </Stack>
            </ScrollArea.Autosize>
        </Stack>
    );
}

export default PrivacyPolicyPage;
